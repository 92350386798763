import { AxiosError } from 'axios';

import { useRef } from 'react';
import { useQuery } from 'react-query';

import { http } from '@hl-portals/libs/http';

import { DISBURSEMENT_SERVICE, SENTRY_SCOPE } from '@hl-portals/constants';

import { useAlertSentry } from '@hl-portals/hooks';

import { formatPaymentHistory } from '@equity/modules/portal/helpers';
import { PaymentHistory } from '@equity/modules/portal/metadata/types';

const {
  FINGERPRINTS,
  LOAN_OFFICER_PORTAL: { TAGS },
} = SENTRY_SCOPE;

export const useFetchPaymentHistory = (loanOfficerId: number) => {
  const originalDataRef = useRef<PaymentHistory[]>([]);
  const { alertSentry } = useAlertSentry();
  const endpoint = DISBURSEMENT_SERVICE.PAYMENT_HISTORY(loanOfficerId);

  const {
    data: paymentHistoryData,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ['FETCH_PAYMENT_HISTORY', loanOfficerId],
    queryFn: async () =>
      http.private({
        url: endpoint,
        translateJsona: true,
      }),
    select: (data) => {
      const formattedData = formatPaymentHistory(data);
      originalDataRef.current = formattedData;

      return formattedData;
    },
    enabled: loanOfficerId !== undefined,
    refetchOnMount: false,
    onError: (error: AxiosError) => {
      alertSentry({
        error,
        fingerprints: [
          FINGERPRINTS.METHOD.GET,
          FINGERPRINTS.DISBURSEMENT_SERVICE.PAYMENTS_HISTORY,
          FINGERPRINTS.DISBURSEMENT_SERVICE.ERROR,
        ],
        customTags: {
          feature: TAGS.FEATURE,
          component: 'PaymentHistory',
          endpoint,
          loanOfficerId,
        },
        extra: {
          responseData: paymentHistoryData,
        },
      });
    },
  });

  return { paymentHistoryData, isFetching, isError, originalDataRef };
};
