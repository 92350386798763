import { sortByPriority } from '@hl-portals/helpers';

import { STATUS_LABEL } from '@equity/modules/portal/components/payment-history/constants';
import {
  PaymentHistory,
  PaymentHistoryColumnSortKey,
} from '@equity/modules/portal/metadata';

export function sortPaymentHistoryByKey(
  array: PaymentHistory[],
  key: PaymentHistoryColumnSortKey
): PaymentHistory[] {
  const isDesc = key.startsWith('-');

  if (
    key === 'departing_property_full_address' ||
    key === ('-departing_property_full_address' as PaymentHistoryColumnSortKey)
  ) {
    const addressSorted = array.sort((a, b) => {
      const [aNum] = a.attributes.departing_property_full_address.split(' ');
      const [bNum] = b.attributes.departing_property_full_address.split(' ');

      // Extract the numerical part from each address
      const numA = parseInt(aNum, 10);
      const numB = parseInt(bNum, 10);

      // Compare the numerical parts
      return numA - numB;
    });
    return isDesc ? addressSorted.reverse() : addressSorted;
  }

  if (
    key === 'pay_by_date' ||
    key === ('-pay_by_date' as PaymentHistoryColumnSortKey)
  ) {
    const payByDateSorted = array.sort((a, b) => {
      return (
        new Date(a.attributes.pay_by_date).getTime() -
        new Date(b.attributes.pay_by_date).getTime()
      );
    });
    return isDesc ? payByDateSorted.reverse() : payByDateSorted;
  }

  if (key === 'status' || key === ('-status' as PaymentHistoryColumnSortKey)) {
    const stageSorted = sortByPriority({
      key: 'attributes.status',
      priorityList: [
        STATUS_LABEL.pending,
        STATUS_LABEL.processing,
        STATUS_LABEL.processed,
        STATUS_LABEL.action_required,
        STATUS_LABEL.not_eligible,
      ],
      listToBeSorted: array,
    });
    return isDesc ? stageSorted.reverse() : stageSorted;
  }

  // Fallback sort
  const newArr = array.sort((a, b) => {
    const aValue = a.attributes[key];
    const bValue = b.attributes[key];

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return aValue.localeCompare(bValue);
    } else if (typeof aValue === 'number' && typeof bValue === 'number') {
      return aValue - bValue;
    }
    return 0;
  });
  return isDesc ? newArr.reverse() : newArr;
}
