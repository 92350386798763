import { createGlobalStyle } from 'styled-components';

import { Box, Timeline } from '@hl-portals/ui';

const GlobalTimelineStyle = createGlobalStyle`
  .timeline__marker {
    background-color: #BBD3EA !important;
    border-color: #F2F9FE !important;
  }

  .timeline__marker__text {
    color: white !important;
  }

  .timeline__item::before {
    top: 0.875rem !important;
  }

  .timeline__item::after {
    top: 0.875rem !important;
  }
`;

export const StepInstructions = ({
  steps,
}: {
  steps: { key: string; label: string }[];
}) => {
  return (
    <Box flex="1" flexDirection="column" paddingY="16px">
      <GlobalTimelineStyle />
      <Timeline.Root colorScheme="blue100">
        {steps.map((step, i) => (
          <Timeline.Item key={step.key} isActive={false}>
            <Timeline.Marker>{i + 1}</Timeline.Marker>
            <Timeline.Label>{step.label}</Timeline.Label>
          </Timeline.Item>
        ))}
      </Timeline.Root>
    </Box>
  );
};
