import { UseMutationOptions, useMutation } from 'react-query';

import { http } from '@hl-portals/libs/http';

import { endpoints } from '@equity/shared/constants/endpoints';

export const useUpdateLead = <Payload,>(
  token: string,
  options?: UseMutationOptions<unknown, unknown, Payload>
) => {
  return useMutation(async (payload: Payload) => {
    await http.public.put(endpoints.lead_data_service.v2.bbys.current, {
      token,
      lead_attributes: payload,
    });
  }, options);
};
