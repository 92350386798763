import { AxiosError } from 'axios';

import { useQuery } from 'react-query';

import { http } from '@hl-portals/libs/http';

import { DISBURSEMENT_SERVICE, SENTRY_SCOPE } from '@hl-portals/constants';

import { useAlertSentry } from '@hl-portals/hooks';

import { formatLoanOfficerDetails } from '@equity/modules/portal/helpers';

import { useCurrentUser } from '../use-current-user';

const {
  FINGERPRINTS,
  LOAN_OFFICER_PORTAL: { TAGS },
} = SENTRY_SCOPE;

interface TrolleyResponse {
  can_receive_payments?: boolean;
  iframe_url?: string;
}

interface FetchTrolleyProps {
  getIframeUrl: boolean;
}

export const useFetchTrolley = ({ getIframeUrl }: FetchTrolleyProps) => {
  const { data: user } = useCurrentUser(undefined, ['loan_officer']);
  const { alertSentry } = useAlertSentry();

  const loanOfficerId = user?.loan_officer?.id;

  const TROLLEY_ENDPOINT =
    DISBURSEMENT_SERVICE.TROLLEY[
      getIframeUrl ? 'GET_IFRAME_URL' : 'HAS_PAYMENT_METHOD'
    ](loanOfficerId);

  const { data, refetch, isFetching, isSuccess, isError } =
    useQuery<TrolleyResponse>({
      queryKey: ['TROLLEY_IFRAME', loanOfficerId],
      queryFn: async () => {
        const res = await http.private({
          url: TROLLEY_ENDPOINT,
          translateJsona: true,
        });

        return res;
      },
      enabled: loanOfficerId !== undefined,
      onError: (error: AxiosError) => {
        alertSentry({
          error,
          fingerprints: [
            FINGERPRINTS.METHOD.GET,
            getIframeUrl
              ? FINGERPRINTS.DISBURSEMENT_SERVICE.TROLLEY.GET_IFRAME_URL
              : FINGERPRINTS.DISBURSEMENT_SERVICE.TROLLEY.HAS_PAYMENT_METHOD,
            FINGERPRINTS.DISBURSEMENT_SERVICE.ERROR,
          ],
          customTags: {
            feature: TAGS.FEATURE,
            component: 'PaymentPreferences',
            endpoint: TROLLEY_ENDPOINT,
            loanOfficerId,
          },
        });
      },
    });

  return {
    data,
    refetch,
    isFetching,
    isSuccess,
    isError,
    loanOfficer: formatLoanOfficerDetails(user),
  };
};
