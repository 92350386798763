import { AxiosError } from 'axios';

import { useMutation, useQueryClient } from 'react-query';

import { SENTRY_SCOPE } from '@hl-portals/constants';

import { enhancedAxios } from '@hl-portals/helpers';

import { useAlertSentry } from '@hl-portals/hooks';

import { CURRENT_USER_QUERY_KEY } from '../use-current-user';
import { updateLoanOfficerProfile } from '../use-update-loan-officer';

const { FINGERPRINTS } = SENTRY_SCOPE;

type AddLenderParams = {
  lender_company_name: string;
  loan_officer_id: number;
  loan_officer_name: string;
  loan_officer_number: string;
};

type AddLenderResponse = {
  id: string;
};

const addLender = async (data: AddLenderParams): Promise<AddLenderResponse> => {
  return enhancedAxios({
    method: 'POST',
    url: '/api/loan-officer-data-service/lenders',
    data: {
      name: data.lender_company_name,
    },
    translateJsona: true,
  });
};

export const useAddLender = (options?: {
  onSuccess?: () => void;
  onError?: (error: AxiosError) => void;
}) => {
  const { alertSentry } = useAlertSentry();
  const queryClient = useQueryClient();
  const { mutate, isLoading, data } = useMutation<
    AddLenderResponse,
    AxiosError,
    Parameters<typeof addLender>[0]
  >({
    mutationFn: addLender,
    onError: (error: AxiosError) => {
      alertSentry({
        error,
        fingerprints: [
          FINGERPRINTS.METHOD.POST,
          FINGERPRINTS.LOAN_OFFICER_DATA_SERVICE.CREATE_LENDER,
          FINGERPRINTS.LOAN_OFFICER_DATA_SERVICE.ERROR,
        ],
      });
      options?.onError?.(error);
    },
    onSuccess: (data: AddLenderResponse, variables) => {
      updateLoanOfficerProfile({
        loan_officer_lender_id: Number(data.id),
        loan_officer_id: variables.loan_officer_id,
        loan_officer_name: variables.loan_officer_name,
        loan_officer_number: variables.loan_officer_number,
      });

      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey.toString().startsWith(CURRENT_USER_QUERY_KEY),
      });
      options?.onSuccess?.();
    },
  });

  return { mutate, isLoading, data };
};
