import { PropsWithChildren, createContext, useContext, useMemo } from 'react';

import { useRouter } from 'next/router';

import { PageLoading } from '@hl-portals/ui';

import { CurrentUser } from '@equity/shared/hooks/use-current-user';
import { Lead, TeamMember } from '@equity/shared/types/lead';

import { useLeadDetailsQuery } from '@equity/modules/portal/hooks/use-portal-lead-query';
import { useTasksQuery } from '@equity/modules/portal/hooks/use-portal-tasks-query';

import { Task } from '../../metadata/types';

type TransactionContextType = {
  lead: Lead;
  tasks: Task[];
  pendingTasks: Task[];
  completedTasks: Task[];
  manager: TeamMember;
  agent: TeamMember;
  assistant: TeamMember;
};

const TransactionContext = createContext<TransactionContextType | undefined>(
  undefined
);

export const TransactionProvider = ({
  children,
  enabled = false,
}: PropsWithChildren<{ user: CurrentUser; enabled?: boolean }>) => {
  const router = useRouter();
  const { id } = router.query as { id: string | undefined };

  const { data: lead, isLoading: isLeadDetailsLoading } = useLeadDetailsQuery(
    id,
    enabled
  );

  const { data: tasks, isLoading: isTasksLoading } = useTasksQuery(id, enabled);

  const { pendingTasks, completedTasks } = useMemo(() => {
    const defaultObj = {
      pendingTasks: [],
      completedTasks: [],
    };

    return (
      tasks?.reduce((obj, task) => {
        if (task.completed_at) {
          return { ...obj, completedTasks: [...obj.completedTasks, task] };
        }
        // Dismissed tasks will still have ready_at date, that is why this should come first
        if (task.dismissed_at) {
          return obj;
        }
        if (task.ready_at) {
          return { ...obj, pendingTasks: [...obj.pendingTasks, task] };
        }
        return obj;
      }, defaultObj) || defaultObj
    );
  }, [tasks]);

  const team = useMemo(() => {
    const baseObj = {
      manager: undefined,
      agent: undefined,
      assistant: undefined,
    };

    if (!lead) return baseObj;

    const {
      transaction_team,
      agent: agentData,
      loan_officer_assistant,
    } = lead.attributes;

    const manager = transaction_team.find(
      (p) => p.role === 'Lender Relationship Manager'
    );

    const agent = !!Object.entries(agentData).length ? agentData : undefined;
    const assistant = !!Object.entries(loan_officer_assistant).length
      ? loan_officer_assistant
      : undefined;

    return {
      manager,
      agent,
      assistant,
    };
  }, [lead]);

  if ((isLeadDetailsLoading && enabled) || (isTasksLoading && enabled)) {
    return <PageLoading />;
  }

  return (
    <TransactionContext.Provider
      value={{
        lead,
        tasks,
        pendingTasks,
        completedTasks,
        manager: team.manager,
        agent: team.agent,
        assistant: team.assistant,
      }}
    >
      {children}
    </TransactionContext.Provider>
  );
};

export const useTransaction = () => {
  const ctx = useContext(TransactionContext);
  if (!ctx) throw new Error('Missing TransactionProvider');
  return ctx;
};
