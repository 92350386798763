import { useQuery } from 'react-query';

import { http } from '@hl-portals/libs/http';

import { DISBURSEMENT_SERVICE, SENTRY_SCOPE } from '@hl-portals/constants';

import { useAlertSentry } from '@hl-portals/hooks';

import { useCurrentUser } from '../use-current-user';
import { FetchPaymentLoanOfficerInsightsResponse } from './types';

const {
  FINGERPRINTS,
  LOAN_OFFICER_PORTAL: { TAGS },
} = SENTRY_SCOPE;

export const useFetchPaymentLoanOfficerInsights = ({
  enabled = true,
}: {
  enabled?: boolean;
}) => {
  const { alertSentry } = useAlertSentry();
  const { data: user } = useCurrentUser(undefined, ['loan_officer']);
  const loanOfficerId = user?.loan_officer?.id;
  const endpoint = DISBURSEMENT_SERVICE.INSIGHTS.LOAN_OFFICERS(loanOfficerId);

  const { data, isSuccess, isFetching, isError } =
    useQuery<FetchPaymentLoanOfficerInsightsResponse>({
      queryKey: ['FETCH_PAYMENT_LOAN_OFFICER_INSIGHTS', loanOfficerId],
      queryFn: () =>
        http.private({
          url: endpoint,
          translateJsona: true,
        }),
      enabled: enabled && loanOfficerId !== undefined,
      onError: (error: Error) => {
        alertSentry({
          error,
          fingerprints: [
            FINGERPRINTS.METHOD.GET,
            FINGERPRINTS.DISBURSEMENT_SERVICE.INSIGHTS.LOAN_OFFICER,
            FINGERPRINTS.DISBURSEMENT_SERVICE.ERROR,
          ],
          customTags: {
            feature: TAGS.FEATURE,
            component: 'PaymentLoanOfficerInsightsCards',
            endpoint,
            loanOfficerId,
          },
          extra: {
            responseData: data,
          },
        });
      },
    });

  return { data, isSuccess, isFetching, isError };
};
