import { sortByPriority } from '@hl-portals/helpers';

import { PipelineViewLead } from '@equity/shared/types/lead';

import { ColumnSortKey } from '../../metadata/constants';

export function sortLeadByKey(
  array: PipelineViewLead[],
  key: ColumnSortKey
): PipelineViewLead[] {
  const isDesc = key.startsWith('-');

  if (
    key === 'pending_tasks_count' ||
    key === ('-pending_tasks_count' as ColumnSortKey)
  ) {
    const pendingTasksSorted = array.sort((a, b) => {
      return (
        a.attributes.pending_tasks_count - b.attributes.pending_tasks_count
      );
    });
    return isDesc ? pendingTasksSorted.reverse() : pendingTasksSorted;
  }

  if (
    key === 'departing_property_full_address' ||
    key === ('-departing_property_full_address' as ColumnSortKey)
  ) {
    const addressSorted = array.sort((a, b) => {
      const [aNum] = a.attributes.departing_property_full_address.split(' ');
      const [bNum] = b.attributes.departing_property_full_address.split(' ');

      // Extract the numerical part from each address
      const numA = parseInt(aNum, 10);
      const numB = parseInt(bNum, 10);

      // Compare the numerical parts
      return numA - numB;
    });
    return isDesc ? addressSorted.reverse() : addressSorted;
  }

  if (key === 'stage' || key === ('-stage' as ColumnSortKey)) {
    const stageSorted = sortByPriority({
      key: 'attributes.stage',
      priorityList: [
        'new',
        'in_review',
        'approved',
        'approved_awiting_signature',
        'agreement_signed',
        'ir_contract',
        'clear_to_fund',
        'ir_closed',
        'dr_closed',
        'canceled',
        'nurture',
        'failed',
      ],
      listToBeSorted: array,
    });
    return isDesc ? stageSorted.reverse() : stageSorted;
  }

  const newArr = array.sort((a, b) => {
    const aValue = a.attributes[key];
    const bValue = b.attributes[key];

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return aValue.localeCompare(bValue);
    } else if (typeof aValue === 'number' && typeof bValue === 'number') {
      return aValue - bValue;
    }
    return 0;
  });
  return isDesc ? newArr.reverse() : newArr;
}
