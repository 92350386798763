import { AxiosError } from 'axios';

import { useQuery } from 'react-query';

import { http } from '@hl-portals/libs/http';

import { SENTRY_SCOPE } from '@hl-portals/constants';

import { useAlertSentry } from '@hl-portals/hooks';

const { FINGERPRINTS } = SENTRY_SCOPE;

type CompanySearchResponse = {
  id: string;
  name: string;
};

export const useCompanySearch = (term: string) => {
  const { alertSentry } = useAlertSentry();
  const endpoint = `/api/loan-officer-data-service/lenders?filter[name]=${term}&fields[lender]=name`;

  const { data, isLoading, isFetching } = useQuery<
    CompanySearchResponse[],
    unknown,
    {
      company_id: string;
      company_name: string;
    }[]
  >({
    queryKey: ['USER_COMPANY_SEARCH', term],
    queryFn: async () => {
      return http.private({
        method: 'GET',
        url: endpoint,
        translateJsona: true,
      });
    },
    enabled: !!term,
    select: (_data: CompanySearchResponse[]) => {
      return _data?.map(({ id, name }) => ({
        company_id: id,
        company_name: name,
      }));
    },
    onError: (error: AxiosError) => {
      alertSentry({
        error,
        fingerprints: [
          FINGERPRINTS.METHOD.GET,
          FINGERPRINTS.LOAN_OFFICER_DATA_SERVICE.SEARCH_LENDER,
          FINGERPRINTS.LOAN_OFFICER_DATA_SERVICE.ERROR,
        ],
        customTags: {
          component: 'UserProfileSettingModal',
          endpoint,
        },
        extra: {
          responseData: data,
        },
      });
    },
  });

  return { data, isLoading, isFetching };
};
