import { useMutation } from 'react-query';

import { http } from '@hl-portals/libs/http';

import { endpoints } from '../../constants/endpoints';
import { signOut, useSession } from 'next-auth/react';

export const useUnimpersonateUser = () => {
  const { status } = useSession();

  return useMutation(
    () =>
      http.private({
        method: 'DELETE',
        url: endpoints.user_auth_service.user_login.stop_impersonation,
      }),
    {
      onSuccess: (res: { return_url: string }) => {
        if (status === 'authenticated') {
          // Only invalidates nextauth szession
          // https://github.com/nextauthjs/next-auth/blob/next-auth%40v4.3.1/packages/next-auth/src/core/routes/signout.ts#L7
          // http.private({
          //   method: 'POST',
          //   url: "/api/auth/signout"
          // });
          signOut({ callbackUrl: res.return_url, redirect: true });
        }
      },
    }
  );
};
