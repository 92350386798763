import { QueryFunction, useQuery } from 'react-query';

import { http } from '@hl-portals/libs/http';

import { endpoints } from '@equity/shared/constants/endpoints';

import { Task } from '@equity/modules/portal/metadata';

export const transactionQueryKeys = {
  lead: (id: string) => ['FETCH_LEAD', id] as const,
  tasks: (id: string) => ['FETCH_TASKS', id] as const,
  fetchTasks: ['FETCH_TASKS'] as const,
};

export const fetchTasks: QueryFunction<
  Task[],
  readonly ['FETCH_TASKS', string]
> = async ({ queryKey }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, leadId] = queryKey;
  const res = await http.private({
    method: 'GET',
    url: endpoints.lead_data_service.loan_officer_portal.tasks.all,
    translateJsona: true,
    params: {
      attachable_id: leadId,
      attachable_type: 'Lead',
    },
  });
  return res;
};

export const useTasksQuery = (leadId: string, enabled = false) => {
  return useQuery({
    queryKey: transactionQueryKeys.tasks(leadId),
    queryFn: fetchTasks,
    enabled: enabled && !!leadId,
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};
