import { AxiosError } from 'axios';

import { UseQueryOptions, useQuery } from 'react-query';

import { http } from '@hl-portals/libs/http';

import { handleErrors } from '@hl-portals/helpers';

import { endpoints } from '../../constants/endpoints';
import { INCLUDE_LOAN_OFFICER_URL } from './constants';

type CurrentUserResponse = {
  data: {
    id: string;
    type: 'user';
    attributes: RawCurrentUser;
    relationships: {};
  };
};

export type RawCurrentUser = {
  email: string;
  first_name: string;
  id: number;
  impersonated: boolean;
  last_name: string;
  phone?: string;
  company_name?: string;
  type: 'client';
  secure_session: boolean;
  full_name?: string;
  loan_officer?: {
    id?: number;
    company_name?: string;
    payments_flow?: string;
    name?: string;
    email?: string;
    phone_number?: {
      number?: string;
    };
    lender?: {
      id?: string;
      name?: string;
    };
  };
  authentication_devices?: {
    id: number;
    name: string;
    authentication_factor_id: number;
    user_id: number;
    config: {
      [key: string]: string;
    };
    created_at: string;
    updated_at: string;
  }[];
};

export type CurrentUser = RawCurrentUser & {
  full_name?: string;
};

export const CURRENT_USER_QUERY_KEY = 'CURRENT_USER_QUERY_KEY';

export const useCurrentUser = (
  options?: UseQueryOptions<CurrentUserResponse['data']['attributes']>,
  include?: (keyof CurrentUser)[]
) => {
  let url = endpoints.user_auth_service.users.current;

  if (include && include.length) {
    url += `?fields[user]=secure_session,${include.join(',')}`;

    if (include.includes('loan_officer')) {
      url += INCLUDE_LOAN_OFFICER_URL;
    }
  }

  return useQuery<CurrentUser>(
    [CURRENT_USER_QUERY_KEY, include?.join('-'), url].filter(Boolean).join('-'),

    async () => {
      const res = await http.private<CurrentUserResponse>({
        method: 'GET',
        url,
        translateJsona: include?.includes('loan_officer'),
      });

      if (include?.includes('loan_officer')) {
        return res as unknown as CurrentUser;
      }

      const user = res.data.attributes || ({} as CurrentUser);
      const full_name = `${user?.first_name || ''} ${user?.last_name || ''}`;

      return { ...user, full_name };
    },
    {
      ...options,
      staleTime: 0,
      cacheTime: 0,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onError: (error) => {
        handleErrors(error as AxiosError);
      },
    }
  );
};
