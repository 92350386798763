export const SubmittedOn = 'created_at';
// Departing Residence Info
export const FullAddress = 'departing_property_full_address';
export const EstimatedHomeValue = 'lender_estimated_home_value';
export const Liens = 'total_lien_balance';
export const EquityUnlockLowRange = 'equity_unlock_low_end_range_intake';
export const EquityUnlockHighRange = 'equity_unlock_high_end_range_intake';
export const TargetEquityUnlockRange = 'target_equity_unlock_range';
export const TargetEquityAmount = 'target_equity_unlock_amount';

// Client Info (Title Holder)
export const Client = 'client';
export const ClientName = 'client_name';
export const ClientEmail = 'client_email';

// Additional Title Holder Info
export const AdditionalClient = 'additional_client';
export const AdditionalClientName = 'additional_client_name';
export const AdditionalClientEmail = 'additional_client_email';

// Agent Info
export const DepartingResidentAgent = 'agent';
export const DRAgentName = 'agent_name';
export const DRAgentEmail = 'agent_email';
export const DRAgentPhone = 'agent_phone';

// Lender Info
export const LoanOfficer = 'loan_officer';
export const LoanOfficerName = 'loan_officer_name';
export const LoanOfficerEmail = 'loan_officer_email';
export const LoanOfficerNumber = 'loan_officer_number';
export const LenderCompanyName = 'loan_officer_company_name';

export const APP_SUMMARY_CARD_SECTION = [
  {
    key: FullAddress,
    label: 'Departing Residence',
  },
  {
    key: EstimatedHomeValue,
    label: 'Estimated Home Value',
  },
  {
    key: Liens,
    label: 'Liens Balance',
  },
  {
    key: TargetEquityUnlockRange,
    label: 'Equity Unlock Estimation',
  },
  {
    key: SubmittedOn,
    label: 'Submitted on',
  },
];

export const APP_SUMMARY_SEE_MORE = {
  DR_INFO: [
    {
      key: FullAddress,
      label: 'Departing Residence',
    },
    {
      key: EstimatedHomeValue,
      label: 'Estimated Home Value',
    },
    {
      key: Liens,
      label: 'Liens Balance',
    },
    {
      key: TargetEquityUnlockRange,
      label: 'Equity Unlock Estimation',
    },
    {
      key: TargetEquityAmount,
      label: 'Target Equity Amount',
    },
  ],
  CLIENT_INFO: [
    {
      key: ClientName,
      label: 'Primary Title Holder',
    },
    {
      key: ClientEmail,
      label: 'Primary Email Address',
    },
    {
      key: AdditionalClientName,
      label: 'Additional Title Holder',
    },
    {
      key: AdditionalClientEmail,
      label: 'Additional Email Address ',
    },
  ],
  AGENT_INFO: [
    {
      key: DRAgentName,
      label: 'Departing Residence Agent',
    },
    {
      key: DRAgentEmail,
      label: 'Email Address',
    },
    {
      key: DRAgentPhone,
      label: 'Phone Number',
    },
  ],
  LENDER_INFO: [
    {
      key: LoanOfficerName,
      label: 'Loan Officer',
    },
    {
      key: LenderCompanyName,
      label: 'Company',
    },
    {
      key: LoanOfficerEmail,
      label: 'Email Address',
    },
    {
      key: LoanOfficerNumber,
      label: 'Phone Number',
    },
  ],
};
