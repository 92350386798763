import { UseQueryOptions, useQuery } from 'react-query';

import { http } from '@hl-portals/libs/http';

import { endpoints } from '../../constants/endpoints';

const VALIDATE_TOKEN_QUERY_KEY = 'VALIDATE_TOKEN';

type ValidateTokenResponse = {
  claimed: boolean;
};

export const useValidateToken = (
  token: string,
  options?: UseQueryOptions<ValidateTokenResponse>
) =>
  useQuery<ValidateTokenResponse>(
    [VALIDATE_TOKEN_QUERY_KEY, token],
    () =>
      http.public
        .get(
          endpoints.user_auth_service.user_login.validate_magic_link_token.token(
            token
          )
        )
        .then((res) => res.data),
    options
  );
