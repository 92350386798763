import { IconTypeProp } from '@hl-portals/ui';

import { LeadStage } from '@equity/shared/types/lead';

import { EquityBoostAssetsKey } from '../forms';

// ================================================
// Lead Stages

export const LEAD_STAGES = [
  {
    label: 'Client Application',
    description:
      'Submit your client to get an Equity Unlock Estimation and start their approval process - No cost or commitment required.',
    keys: ['submit_client'],
    style: {
      color: '#3A3C40',
      borderColor: '#EEF0F6',
      bgcolor: '#EEF0F6',
      showMarker: false,
    },
  },
  {
    label: 'Pending Photos',
    description:
      'Upload photos of the departing residence to evaluate the property’s condition.',
    keys: ['new'],
    style: {
      color: '#CA0D02',
      borderColor: '#FEEBEA',
      bgcolor: '#FFF4F4',
      showMarker: true,
    },
  },
  {
    label: 'In Review',
    description:
      'We will reach out to you within the next 24-48 hours to provide you with an approval decision.',
    keys: ['in_review'],
    style: {
      color: '#1192E5',
      borderColor: '#D0ECFF',
      bgcolor: '#F2F9FE',
      showMarker: false,
    },
  },
  {
    label: 'Conditionally Approved',
    description:
      'Your client is conditionally approved. Your Lender Relationship Manager will reach out to discuss the details.',
    keys: ['approved'],
    style: {
      color: '#177856',
      borderColor: '#CEF0E4',
      bgcolor: '#E6F7F1',
      showMarker: false,
    },
  },
  {
    label: 'Awaiting Client Signature',
    description:
      "The agreement has been sent via email, and we're awaiting the client's signature.",
    keys: ['approved_awiting_signature'],
    style: {
      color: '#705300',
      borderColor: '#FFECB5',
      bgcolor: '#FFF8E1',
      showMarker: true,
      markerColor: '#BB8E00',
    },
  },
  {
    label: 'Awaiting Purchase Contract',
    description:
      'Provide your client’s purchase contract as soon as they are under contract on their new home.',
    keys: ['agreement_signed'],
    style: {
      color: '#D64F00',
      borderColor: '#FFDABD',
      bgcolor: '#FFF6EB',
      showMarker: true,
      markerColor: '#F06C00',
    },
  },
  {
    label: 'Loan Processing',
    description:
      'Upload the necessary documentation and client information to process the equity unlock loan.',
    keys: ['ir_contract'],
    style: {
      color: '#1192E5',
      borderColor: '#8BD1FF',
      bgcolor: '#F2F9FE',
      showMarker: true,
    },
  },
  {
    label: 'Clear to Fund',
    description:
      'We are ready to wire the funds for the Incoming Residence purchase.',
    keys: ['clear_to_fund'],
    style: {
      color: '#177856',
      borderColor: '#38BF8F',
      bgcolor: '#E6F7F1',
    },
  },
  {
    label: 'Sell the Departing Residence',
    description:
      "We are working with your client's agent to sell the departing residence for full market value.",
    keys: ['ir_closed'],
    style: {
      color: '#3A3C40',
      borderColor: '#DBDFE6',
      bgcolor: '#EEF0F6',
    },
  },
  {
    label: 'Completed',
    description:
      'The departing residence has sold and your client has received any net proceeds from the sale.',
    keys: ['dr_closed'],
    style: {
      color: '#273653',
      borderColor: '#DBDFE6',
      bgcolor: '#FFFFFF',
    },
  },
  {
    label: 'Inactive',
    description: 'The transaction is no longer active.',
    keys: ['canceled', 'nurture', 'failed'],
    style: {
      color: '#8E929C',
      borderColor: '#EEF0F6',
      bgcolor: '#EEF0F6',
    },
  },
];

export type LeadStageUIV1 = typeof LEAD_STAGES[number];

export const LEAD_STAGES_V2 = [
  {
    label: 'Complete Application',
    keys: ['new'],
    style: {
      color: '#D64F00',
      borderColor: '#FFDABD',
      bgcolor: '#FFF6EB',
    },
  },
  {
    label: 'Application Review',
    keys: ['in_review'],
    style: {
      color: '#1192E5',
      borderColor: '#F2F9FE',
      bgcolor: '#D0ECFF',
    },
  },
  {
    label: 'Conditionally Approved',
    keys: ['approved', 'approved_awiting_signature'],
    style: {
      color: '#177856',
      borderColor: '#CEF0E4',
      bgcolor: '#E6F7F1',
    },
  },
  {
    label: 'Buy New Home',
    keys: ['agreement_signed', 'ir_contract', 'clear_to_fund'],
    style: {
      color: '#705300',
      borderColor: '#FFDABD',
      bgcolor: '#FFECB5',
    },
  },
  {
    label: 'Sell Old Home',
    keys: ['ir_closed', 'dr_closed'],
    style: {
      color: '#3A3C40',
      borderColor: '#DBDFE6',
      bgcolor: '#F5F6F9',
    },
  },
  {
    label: 'Inactive',
    keys: ['canceled', 'nurture', 'failed'],
    style: {
      color: '#8E929C',
      borderColor: '#EEF0F6',
      bgcolor: '#EEF0F6',
    },
  },
];

export type LeadStageUIV2 = typeof LEAD_STAGES_V2[number];

// ================================================
// Menu Items

type MenuData = {
  id: string;
  href?: string;
  idleIcon: IconTypeProp;
  activeIcon: IconTypeProp;
  text: string;
  target?: string;
  children?: {
    id: string;
    icon: IconTypeProp;
    title: string;
    description: string;
    href?: string;
    target?: string;
  }[];
};

type MenuItem = MenuData | ((href: string) => MenuData);

export const MENU_ITEMS: MenuItem[] = [
  {
    id: 'home',
    href: '/portal',
    idleIcon: 'homeOutline',
    activeIcon: 'home',
    text: 'Pipeline',
  },
  (href) => ({
    id: 'submit_client',
    href,
    idleIcon: 'plus',
    activeIcon: 'plus',
    text: 'Submit client',
  }),
  {
    id: 'payments',
    href: '/portal/payments',
    idleIcon: 'paymentOutline',
    activeIcon: 'payment',
    text: 'Payments',
  },
  {
    id: 'help_and_resources',
    idleIcon: 'questionCircleLine',
    activeIcon: 'questionCircle',
    text: 'Help & Resources',
    children: [
      {
        id: 'help_center',
        icon: 'questionCircleLine',
        title: 'Help Center',
        description:
          'Get answers to commonly asked questions, details on the program, and much more.',
        href: 'https://help.homelight.com/lender-frequently-asked-questions',
        target: '_blank',
      },
      {
        id: 'contact_us',
        icon: 'contactUsMessageBubble',
        title: 'Contact us',
        description:
          'Leave us a message and our team of experts will be in touch',
        href: '#',
      },
    ],
  },
  // TODO: Enable when we have password reset
  // {
  //   id: 'settings',
  //   href: '/settings',
  //   idleIcon: 'settingsLine',
  //   activeIcon: 'settings',
  //   text: 'Settings',
  // },
];

// ================================================
// Welcome Banner Local Storage Key

export const WELCOME_BANNER_DISMISSED_KEY = 'WELCOME_BANNER_DISMISSED_KEY';

// ================================================
// Column Sort Keys

export const COLUMN_SORT_KEYS = {
  primary_name: 'primary_name',
  departing_property_full_address: 'departing_property_full_address',
  stage: 'stage',
  pending_tasks_count: 'pending_tasks_count',
};

export type ColumnSortKey = keyof typeof COLUMN_SORT_KEYS;

// ================================================
// Payment History Column Sort Keys

export const PAYMENT_HISTORY_COLUMN_SORT_KEYS = {
  primary_name: 'primary_name',
  departing_property_full_address: 'departing_property_full_address',
  amount_cents: 'amount_cents',
  pay_by_date: 'pay_by_date',
  status: 'status',
};

export type PaymentHistoryColumnSortKey =
  keyof typeof PAYMENT_HISTORY_COLUMN_SORT_KEYS;

// ================================================
// Equity Boost task modal steps

export const EQUITY_BOOST_TASK_MODAL_STEPS = {
  INTRO: 'Intro',
  TARGET_EQUITY_LOAN: 'Target Equity Loan',
  ESTIMATED_CREDIT_SCORE: 'Estimated Credit Score',
  TARGET_EQUITY_LOAN_FIX: 'Target Equity Loan Fix',
  ADDITIONAL_CLIENT_CREDIT_SCORE: 'Additional Client Credit Score',
  ASSETS: 'Assets',
  UPLOAD_DOCUMENTS: 'Upload Documents',
};

// ================================================
// Equity Boost task modal steps

export const REVIEW_BBYS_AGREEMENT_TASK_MODAL_STEPS: Record<
  string,
  ReviewBBYSAgreementTaskModalSteps
> = {
  KEY_NUMBERS: 'Key Numbers',
  BACKUP_OFFER: 'Backup Offer',
  INVITE_CLIENT_TO_SIGN: 'Invite Client to Sign',
};

export type ReviewBBYSAgreementTaskModalSteps =
  | 'Key Numbers'
  | 'Backup Offer'
  | 'Invite Client to Sign';

export const REVIEW_BBYS_AGREEMENT_TASK_MODAL_STEPS_LIST = Object.entries(
  REVIEW_BBYS_AGREEMENT_TASK_MODAL_STEPS
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
).map(([_, label]) => label);
// ================================================
// Equity Boost Assets

export const EQUITY_BOOST_ASSETS_LABELS: Record<EquityBoostAssetsKey, string> =
  {
    checking: 'Checking',
    savings: 'Savings',
    gift_funds: 'Gift funds',
    fourty_one_k: '401K',
    ira: 'IRA',
    investment_or_brokerage: 'Investment or Brokerage',
  };

// ================================================
// Progress Tracker Timeline
// This is in the correct order

export type ProgressTrackerStep =
  | 'Complete Application'
  | 'Application Review'
  | 'Conditionally Approved'
  | 'Buy New Home'
  | 'Sell Old Home'
  | 'Inactive';

export const PROGRESS_TRACKER_STEPS_MAP: Record<string, ProgressTrackerStep> = {
  COMPLETE_APPLICATION: 'Complete Application',
  APPLICATION_REVIEW: 'Application Review',
  CONDITIONALLY_APPROVED: 'Conditionally Approved',
  BUY_NEW_HOME: 'Buy New Home',
  SELL_OLD_HOME: 'Sell Old Home',
  INACTIVE: 'Inactive',
};

export const PROGRESS_TRACKER_STEPS_LIST = Object.entries(
  PROGRESS_TRACKER_STEPS_MAP
)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  .filter(([_, label]) => label !== 'Inactive')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  .map(([_, label]) => label);

export const STAGE_TO_STEP: Record<LeadStage, ProgressTrackerStep> = {
  new: 'Complete Application',
  in_review: 'Application Review',
  approved: 'Conditionally Approved',
  approved_awiting_signature: 'Conditionally Approved',
  agreement_signed: 'Sell Old Home',
  ir_contract: 'Buy New Home',
  clear_to_fund: 'Buy New Home',
  ir_closed: 'Sell Old Home',
  dr_closed: 'Sell Old Home',
  canceled: 'Inactive',
  nurture: 'Inactive',
  failed: 'Inactive',
};
