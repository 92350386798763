import { UseQueryOptions, useQuery } from 'react-query';

import { http } from '@hl-portals/libs/http';

import { endpoints } from '../../constants/endpoints';

export const USE_CHECK_EXISTING_EMAIL_QUERY_KEY = (email: string) => [
  'USE_CHECK_EMAIL_QUERY_KEY',
  email,
];

export type UseCheckExistingEmailResponse = {
  exists: boolean;
};

export const useCheckExsitingEmail = (
  email: string,
  options: UseQueryOptions<UseCheckExistingEmailResponse> = {}
) =>
  useQuery<UseCheckExistingEmailResponse>(
    USE_CHECK_EXISTING_EMAIL_QUERY_KEY(email),
    async () => {
      const res = await http.public.get(
        endpoints.user_auth_service.users.exists,
        {
          params: {
            email,
          },
        }
      );
      return res.data;
    },
    { enabled: !!email, ...options }
  );
