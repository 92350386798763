export const STATUS_BACKGROUND_COLORS = {
  processed: '#E8FBF0', // Green background
  processing: '#E9F6FF', // Blue background
  pending: '#FCF1BA', // Yellow background
  action_required: '#FFECD1', // Red background
  not_eligible: '#EEF0F6', // Gray background
} as const;

export const STATUS_COLORS = {
  processed: '#3B855E',
  processing: '#0C6EBA',
  pending: '#705300',
  action_required: '#D64F00',
  not_eligible: '#55585E',
} as const;

export const COLUMN_SORT_KEYS = {
  primary_name: 'primary_name',
  departing_property_full_address: 'departing_property_full_address',
  amount_cents: 'amount_cents',
  pay_by_date: 'pay_by_date',
  status: 'status',
};

export const PROCESSED = 'Processed';
export const PROCESSING = 'Processing';
export const PENDING = 'Waiting on home sale'; // By design we map status 'pending' to the label 'Waiting on home sale'
export const ACTION_REQUIRED = 'Action required';
export const NOT_ELIGIBLE = 'Not eligible';

export const STATUS_LABEL = {
  processed: PROCESSED,
  processing: PROCESSING,
  pending: PENDING,
  action_required: ACTION_REQUIRED,
  not_eligible: NOT_ELIGIBLE,
} as const;
