import { PaymentHistory } from '@equity/modules/portal/metadata/types';

interface ApiResponse {
  type: string;
  id: string;
  amount_cents: number;
  status: string;
  pay_by_date: string;
  lead: {
    type: string;
    id: string;
    name: string;
  };
  bbys_lead: {
    type: string;
    id: string;
    departing_property_full_address: string;
  };
  relationshipNames: string[];
}

export const formatPaymentHistory = (data: ApiResponse[]): PaymentHistory[] => {
  return data.map((item) => ({
    attributes: {
      lead_id: Number(item.lead.id),
      primary_name: item.lead.name,
      departing_property_full_address:
        item.bbys_lead.departing_property_full_address,
      amount_cents: item.amount_cents,
      status: item.status,
      pay_by_date: new Date(item.pay_by_date).toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      }),
    },
  }));
};
