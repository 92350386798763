import styled from 'styled-components';

import { PropsWithChildren } from 'react';

import {
  Box,
  Button,
  Icon,
  IndentedText,
  OnboardingModal,
  Paragraph,
  useMultiStepLinearContext,
} from '@hl-portals/ui';

import { EQUITY_BOOST_TASK_MODAL_STEPS } from '@equity/modules/portal/metadata/constants';

const CheckIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.6853 5.34591C22.1049 5.76546 22.1049 6.44388 21.6853 6.85897L9.90225 18.6465C9.4827 19.0661 8.80429 19.0661 8.3892 18.6465L2.31466 12.5764C1.89511 12.1569 1.89511 11.4785 2.31466 11.0634C2.73421 10.6483 3.41263 10.6438 3.82772 11.0634L9.13903 16.3747L20.1678 5.34591C20.5874 4.92636 21.2658 4.92636 21.6809 5.34591H21.6853Z"
      fill="#273653"
    />
  </svg>
);

export const Title = styled(Paragraph)`
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  background: linear-gradient(to right, #1192e5 0%, #38bf8f 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const BulletItem = ({ children }: PropsWithChildren) => (
  <Box alignItems="center" gap="0.75rem">
    <CheckIcon />
    <Paragraph>{children}</Paragraph>
  </Box>
);

const EquityBoostIntroDetails = ({ onClick }: { onClick: () => void }) => {
  return (
    <Box
      flex="1"
      flexDirection="column"
      alignItems="flex-start"
      overflowY="auto"
    >
      <Box mb="1.5rem" flexDirection="column">
        <Box mb="0.5rem" alignItems="center" gap="0.5rem">
          <Title>EQUITY BOOST</Title>
          <Icon type="growthIcon" />
        </Box>

        <Paragraph mb="1rem" variant="heading-2">
          Unlock more equity with assets
        </Paragraph>
        <Paragraph>
          Use additional assets to increase the equity loan amount for the new
          home, up to an 85% combined loan-to-value ratio
        </Paragraph>
      </Box>

      <Box mb="1.5rem" flexDirection="column" gap="1rem">
        <Paragraph color="#72757D">Assets accepted</Paragraph>
        <BulletItem>Cash in a checking or savings account</BulletItem>
        <BulletItem>401K account</BulletItem>
        <BulletItem>IRA account</BulletItem>
        <BulletItem>Investment/Brokerage account</BulletItem>
        <BulletItem>Gift funds</BulletItem>
      </Box>

      <Box mb="1.5rem" flexDirection="column">
        <Paragraph mb="1rem" color="#72757D">
          Apply with:
        </Paragraph>
        <IndentedText indentation="0.5rem">
          Borrower(s) estimated credit score
        </IndentedText>
        <IndentedText indentation="0.5rem">
          Account statements of the assets that would be used.
        </IndentedText>
      </Box>

      <Button mb="1.5rem" size="large" onClick={onClick}>
        Apply for Equity Boost
      </Button>

      <Box flexDirection="column">
        <Paragraph mb="1rem" variant="text-small" color="#55585E">
          Unacceptable Assets:
        </Paragraph>
        <IndentedText indentation="0.5rem" variant="text-small" color="#55585E">
          Cryptocurrency & business assets
        </IndentedText>
        <IndentedText indentation="0.5rem" variant="text-small" color="#55585E">
          Assets already allocated for the new home purchase
        </IndentedText>
      </Box>
    </Box>
  );
};

export const Intro = () => {
  const { goTo } = useMultiStepLinearContext();

  return (
    <OnboardingModal
      onboardingDetails={
        <EquityBoostIntroDetails
          onClick={() => goTo(EQUITY_BOOST_TASK_MODAL_STEPS.TARGET_EQUITY_LOAN)}
        />
      }
    />
  );
};
