import { useQuery } from 'react-query';

import { http } from '@hl-portals/libs/http';

import { DISBURSEMENT_SERVICE, SENTRY_SCOPE } from '@hl-portals/constants';

import { useAlertSentry } from '@hl-portals/hooks';

import { useTransaction } from '@equity/modules/portal/contexts/transaction';

const {
  FINGERPRINTS,
  LOAN_OFFICER_PORTAL: { TAGS },
} = SENTRY_SCOPE;

export const useFetchPaymentLeadInsights = () => {
  const { alertSentry } = useAlertSentry();
  const { lead } = useTransaction();

  const leadId = lead?.attributes?.lead_id;

  const endpoint = DISBURSEMENT_SERVICE.INSIGHTS.LEADS(leadId);

  const {
    data: paymentLeadInsights,
    isFetching,
    isSuccess,
    isError,
  } = useQuery({
    queryKey: ['FETCH_PAYMENT_LEAD_INSIGHTS', leadId],
    queryFn: () =>
      http.private({
        url: endpoint,
        translateJsona: true,
      }),
    enabled: leadId !== undefined,
    onError: (error: Error) => {
      alertSentry({
        error,
        fingerprints: [
          FINGERPRINTS.METHOD.GET,
          FINGERPRINTS.DISBURSEMENT_SERVICE.INSIGHTS.LEADS,
          FINGERPRINTS.DISBURSEMENT_SERVICE.ERROR,
        ],
        customTags: {
          feature: TAGS.FEATURE,
          component: 'PaymentLeadInsights',
          endpoint,
          leadId,
        },
        extra: {
          responseData: paymentLeadInsights,
        },
      });
    },
  });

  return {
    lead,
    endpoint,
    paymentLeadInsights,
    isFetching,
    isSuccess,
    isError,
  };
};
