import { AxiosError } from 'axios';

import { useMutation, useQueryClient } from 'react-query';

import { SENTRY_SCOPE } from '@hl-portals/constants';

import { enhancedAxios, removePhoneFormat } from '@hl-portals/helpers';

import { useAlertSentry } from '@hl-portals/hooks';

import { CURRENT_USER_QUERY_KEY } from '../use-current-user';

const { FINGERPRINTS } = SENTRY_SCOPE;

export const updateLoanOfficerProfile = async (data: {
  loan_officer_lender_id?: number;
  loan_officer_id?: number;
  loan_officer_name?: string;
  loan_officer_number?: string;
}) => {
  return enhancedAxios({
    method: 'PUT',
    url: `/api/loan-officer-data-service/loan-officers/${data.loan_officer_id}`,
    data: {
      ...(data.loan_officer_lender_id && {
        loan_officer_lender_id: Number(data.loan_officer_lender_id),
      }),
      ...(data.loan_officer_name && {
        loan_officer_name: data.loan_officer_name,
      }),
      ...(data.loan_officer_number && {
        loan_officer_number: Number(
          removePhoneFormat(data.loan_officer_number)
        ),
      }),
    },
    translateJsona: true,
  });
};

export const useUpdateLoanOfficer = (options?: {
  onSuccess?: () => void;
  onError?: (error: AxiosError) => void;
}) => {
  const { alertSentry } = useAlertSentry();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation<
    { success: boolean },
    AxiosError,
    Parameters<typeof updateLoanOfficerProfile>[0]
  >({
    mutationFn: updateLoanOfficerProfile,

    onError: (error: AxiosError) => {
      alertSentry({
        error,
        fingerprints: [
          FINGERPRINTS.METHOD.PATCH,
          FINGERPRINTS.LOAN_OFFICER_DATA_SERVICE.UPDATE_LOAN_OFFICER,
          FINGERPRINTS.LOAN_OFFICER_DATA_SERVICE.ERROR,
        ],
      });
      options?.onError?.(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey.toString().startsWith(CURRENT_USER_QUERY_KEY),
      });

      options?.onSuccess?.();
    },
  });

  return { mutate, isLoading };
};
