import { AxiosError } from 'axios';

import { QueryFunction, useQuery } from 'react-query';

import { useRouter } from 'next/router';

import { http } from '@hl-portals/libs/http';

import { endpoints } from '@equity/shared/constants/endpoints';
import { Lead } from '@equity/shared/types/lead';

import { transactionQueryKeys } from '../use-portal-tasks-query';

export const fetchLeadDetails: QueryFunction<
  Lead,
  readonly ['FETCH_LEAD', string]
> = async ({ queryKey }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, id] = queryKey;
  const res = await http.private({
    method: 'GET',
    url: endpoints.lead_data_service.loan_officer_portal.leads.id(id),
  });
  return res.data;
};

export const useLeadDetailsQuery = (id: string, enabled = false) => {
  const router = useRouter();
  return useQuery({
    queryKey: transactionQueryKeys.lead(id),
    queryFn: fetchLeadDetails,
    enabled: enabled && !!id,
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    onError: (error: AxiosError) => {
      if (error.response?.status === 404) {
        router.push('/404');
      }
    },
  });
};
