/*
Handles internationalization
Properly rounds numbers
Adds currency symbol
Handles edge cases (very large numbers, negative numbers)
Returns a properly formatted string like "$1,500.99"
*/

export const formatCentsToDollars = (cents: number): string => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(cents / 100);
};
