import dayjs from 'dayjs';

import { formatCurrency, getEquityUnlockRange } from '@hl-portals/helpers';

import { Lead } from '@equity/shared/types/lead';

import {
  AdditionalClient,
  AdditionalClientEmail,
  AdditionalClientName,
  Client,
  ClientEmail,
  ClientName,
  DRAgentEmail,
  DRAgentName,
  DRAgentPhone,
  DepartingResidentAgent,
  EquityUnlockHighRange,
  EquityUnlockLowRange,
  EstimatedHomeValue,
  FullAddress,
  LenderCompanyName,
  Liens,
  LoanOfficer,
  LoanOfficerEmail,
  LoanOfficerName,
  LoanOfficerNumber,
  SubmittedOn,
  TargetEquityAmount,
  TargetEquityUnlockRange,
} from '../../components/transaction-cards/application-summary/constants';
import { AppSummaryCard, AppSummarySeeMore } from '../../metadata/types';

export function mapAppSummary(lead: Lead): AppSummaryCard | AppSummarySeeMore {
  const appSummarySet = new Set([
    AdditionalClient,
    DepartingResidentAgent,
    EquityUnlockHighRange,
    EquityUnlockLowRange,
    EstimatedHomeValue,
    FullAddress,
    Liens,
    LoanOfficer,
    Client,
    SubmittedOn,
    TargetEquityAmount,
  ]);

  const map = Object.keys(lead?.attributes || {}).reduce((acc, key) => {
    if (appSummarySet.has(key)) {
      if (key === SubmittedOn) {
        acc[key] = dayjs(lead?.attributes[key]).format('MMMM D, YYYY');
      } else if (
        key === EstimatedHomeValue ||
        key === Liens ||
        key === TargetEquityAmount
      ) {
        acc[key] = formatCurrency({ value: lead?.attributes[key] });
      } else if (key === Client) {
        acc[ClientName] = lead?.attributes[key]?.name;
        acc[ClientEmail] = lead?.attributes[key]?.email;
      } else if (key === AdditionalClient) {
        acc[AdditionalClientName] = lead?.attributes[key]?.name;
        acc[AdditionalClientEmail] = lead?.attributes[key]?.email;
      } else if (key === DepartingResidentAgent) {
        acc[DRAgentName] = lead?.attributes[key]?.name;
        acc[DRAgentEmail] = lead?.attributes[key]?.email;
        acc[DRAgentPhone] = lead?.attributes[key]?.phone;
      } else if (key === LoanOfficer) {
        acc[LoanOfficerName] = lead?.attributes[key]?.name;
        acc[LoanOfficerEmail] = lead?.attributes[key]?.email;
        acc[LoanOfficerNumber] = lead?.attributes[key]?.phone;
        acc[LenderCompanyName] = lead?.attributes[key]?.company_name;
      } else {
        acc[key] = lead?.attributes[key];
      }
    }

    return acc;
  }, {} as AppSummaryCard | AppSummarySeeMore);

  map[TargetEquityUnlockRange] = getEquityUnlockRange(
    parseInt(map[EquityUnlockLowRange], 10),
    parseInt(map[EquityUnlockHighRange], 10)
  );

  return map;
}
